import React, { useState, useRef, useEffect } from 'react';
import "./workflowStyle.css"
import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import { Stage, Layer, Line, Arrow} from 'react-konva';
import FloatingPanel from '../../components/FloatingPanel'
import * as styleFunctions from '../../functions/styleFunctions'
import * as oomnielabsApi from '../../apis/oomnielabs'

import view_icon from '../../assets/icons/view_icon.png';

const Generate = (props) => {

  const mode = useSelector(state => state.environment.mode);

  const workflowData = props.workflowData

  const [steps, setSteps] = useState(workflowData.nodes);
  const [connections, setConnections] = useState(workflowData.connections);
  const [lastNodeId, setLastNodeId] = useState(1)
  const [firstNodeId, setFirstNodeId] = useState(1)


  class Workflow{
    
  }

  const getLastNode = async ()=>{
    let maxId = 1
    await (workflowData.nodes).forEach((item=>{
      maxId = Math.max(maxId,parseInt(item.id))
    }))
    setLastNodeId(maxId)
  }

  useEffect(()=>{
    getLastNode()
  },[steps])

  

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState(null);
  const [reRender, setReRender] = useState(false);
  
  const mainContainerRef = useRef(null)
  const stepRefs = useRef({});
  const stageRef = useRef(null);

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {

    const updateContainerSize = () => {
      if (mainContainerRef.current) {
        const { width, height } = mainContainerRef.current.getBoundingClientRect();
        setContainerSize({ width, height });
      }
    };
    updateContainerSize();
    window.addEventListener('resize', updateContainerSize);
    return () => window.removeEventListener('resize', updateContainerSize);
  }, []);


  const handleDrag = (e, data, id) => {
    setSteps(prevSteps =>
      prevSteps.map(step => (step.id === id ? { ...step, x: data.x, y: data.y } : step))
    );
    setReRender(!reRender); 
  };


  useEffect(() => {
    setReRender(!reRender);
  }, []);

  // Open modal for editing step settings
  const openModal = (step) => {
    setSelectedStep(step);
    setModalIsOpen(true);
  };

 
  const [renderedConnections, setRenderedConnections] = useState([]);
  useEffect(() => {
    setRenderedConnections([]);
    
    connections.forEach((conn, index) => {
      setTimeout(() => {
        setRenderedConnections(prev => [...prev, conn]);
      }, (600 + index * 300));
    });
  }, []);


  // Function to calculate arrow positions based on the cases
  const calculateArrowPoints = (start, end) => {
    const startRect = stepRefs.current[start]?.getBoundingClientRect();
    const endRect = stepRefs.current[end]?.getBoundingClientRect();

    if (!startRect || !endRect) return null;

    const startX = startRect.left + startRect.width / 2;
    const startY = startRect.top + startRect.height / 2;
    const endX = endRect.left + endRect.width / 2;
    const endY = endRect.top + endRect.height / 2;

    const halfWidthStart = startRect.width / 2;
    const halfWidthEnd = endRect.width / 2;

    // Determine case
    if (endX < startX - halfWidthStart || endX > startX + halfWidthStart) {
      // Case 1 and Case 2
      if (endY < startY) {
        // Case 1
        return [startX, startRect.top, endX, endRect.bottom];
      } else {
        // Case 2
        return [startX, startRect.bottom, endX, endRect.top];
      }
    } else {
      // Case 3 and Case 4
      if (endX > startX) {
        // Case 3
        return [startRect.right, startY, endRect.left, endY];
      } else {
        // Case 4
        return [startRect.left, startY, endRect.right, endY];
      }
    }
  };


  return (
      <div 
        ref = {mainContainerRef}
        className={`w-[5000px] h-[5000px] relative rounded-md mt-3 overflow-auto fade-in`}>
        
        {steps.map((step, index) => (
          
            <Draggable
              key={index}
              position={{ x: step.x, y: step.y }}
              onDrag={(e, data) => handleDrag(e, data, step.id)}
          >
            <div
              ref={(el) => (stepRefs.current[step.id] = el)}
              className={`workflow-node-mode-${mode} fade-in`}
              style={{
                backgroundColor: step.id === firstNodeId ? 'green' : step.id === lastNodeId ? 'rgb(200,0,50)' : null,
                color: step.id === firstNodeId || step.id === lastNodeId  ? 'white' : null,
                fontSize: step.id === firstNodeId || step.id === lastNodeId ? '12px' : null,
                fontWeight: step.id === firstNodeId || step.id === lastNodeId ? 'bold' : null,
                animationDelay: `${index * 0.3}s`
              }}
            >
              <div className="flex relative flex-col w-full h-full">
                <div className="flex absolute w-full justify-end">
                  <img
                    className="h-[20px] w-[20px] cursor-pointer"
                    src={view_icon}
                    onClick={() => openModal(step)}
                  />
                </div>
                <div className="flex w-full h-[100%] justify-center items-center">{step.name}</div>
              </div>
            </div>
          </Draggable>

        ))}

    <Stage width={containerSize.width} height={containerSize.height} ref={stageRef}>

    <Layer>
      {renderedConnections.map((conn, index) => {
      const fromStep = steps.find(step => step.id === conn.from);
      const toStep = steps.find(step => step.id === conn.to);

    if (fromStep && toStep && stepRefs.current[fromStep.id] && stepRefs.current[toStep.id] && stageRef.current) {
      // Get bounding rectangles of the nodes
      const fromRect = stepRefs.current[fromStep.id].getBoundingClientRect();
      const toRect = stepRefs.current[toStep.id].getBoundingClientRect();

      // Get the offset of the Stage (canvas) relative to the viewport
      const stageRect = stageRef.current.container().getBoundingClientRect();
      
      // Calculate the start and end points
      const fromX = fromRect.left + fromRect.width / 2 - stageRect.left;
      const fromY = fromRect.bottom - stageRect.top;
      const toX = toRect.left + toRect.width / 2 - stageRect.left;
      const toY = toRect.top - stageRect.top;

      // Adjust control points for a smooth, curved connection
      const controlPointOffset = Math.abs(toY - fromY)/0.5;
      const controlX1 = fromX;
      const controlY1 = fromY + controlPointOffset;
      const controlX2 = toX;
      const controlY2 = toY - controlPointOffset;

      return (
        <Arrow
          key={index}
          points={[fromX, fromY, controlX1, controlY1, controlX2, controlY2, toX, toY]}
          stroke="rgb(200,200,200)"
          strokeWidth={1}
          fill="white"
          pointerLength={15} 
          pointerWidth={15}  
          bezier = {true} 
          className="fade-in"
          
          style={{
            animationDelay: `${index * 0.3}s`, // Staggered animation delay
          }}
        />
      );
    }
    return null;
  })}
  </Layer>


  </Stage>

    {modalIsOpen &&  
    
    <div className="flex absolute w-100vw h-100vh z-900">
      
      <FloatingPanel
        title = {`${selectedStep.name}`}
        height = "800px"
        width = "600px"
        headerColor = "white"
        headerTextColor = "black"
        displayPanel = {(e)=>setModalIsOpen(false)}
        secondaryColor = {`secondary-color-${mode}`}
    >

      <div className={`flex flex-col body-mode-${mode} w-full`}>
        <div className={`flex w-full bg-white h-[100px] p-5 text-[12px] font-bold`}>
          Please modify inputs to adjust the workflow action.
        </div>

        <div className={`w-full bg-[rgb(245,245,245)] h-[400px] p-5`}>
            
            <div className={`flex flex-col text-[14px] text-[rgb(150,150,150)] w-full h-[100%]`}>

            <div className={`flex items-center w-full h-[75px] border-b border-[rgb(200,200,200)] border-dotted justify-between`}>
                <div className="flex w-[200px] h-[25px]">Name</div>
                <div className="flex items-center w-3/4 h-[40px] bg-[rgb(200,200,200)] p-2">{selectedStep.name}</div>
              </div>
              <div className={`flex items-center w-full h-[75px] border-b border-b-[rgb(200,200,200)] border-dotted justify-between`}>
                <div className="flex w-[200px] h-[25px]">Module</div>
                <div className="flex items-center w-3/4 h-[40px] bg-[rgb(200,200,200)] p-2">{"Custom Object Records"}</div>
              </div>
              <div className={`flex items-center w-full h-[75px] border-b border-b-[rgb(200,200,200)] border-dotted justify-between`}>
                <div className="flex w-[200px] h-[25px]">URL</div>
                <div className="flex items-center w-3/4 h-[40px] bg-[rgb(200,200,200)] p-2">{"api/v3/{custom_object_id}"}</div>
              </div>
              <div className={`flex items-center w-full h-[75px] border-b border-b-[rgb(200,200,200)] border-dotted justify-between`}>
                <div className="flex w-[200px] h-[25px]">HTTP Methods</div>
                <div className="flex items-center w-3/4 h-[40px] bg-[rgb(200,200,200)] p-2">{"POST"}</div>
              </div>

              <div className={`flex items-center w-full h-[75px] border-b border-b-[rgb(200,200,200)]border-dotted justify-between`}>
                <div className="flex w-[200px] h-[25px]">Path Params</div>
                <div className="flex items-center w-3/4 h-[40px] bg-[rgb(200,200,200)] p-2">{"co_requests"}</div>
              </div>

              <div className={`flex items-center w-full h-[75px] border-b border-b-[rgb(200,200,200)] border-dotted justify-between`}>
                <div className="flex w-[200px] h-[25px]">Query Params</div>
                <div className={`flex w-3/4 justify-start`}>
                  <div className="flex items-center w-[150px] h-[40px] bg-[rgb(200,200,200)] p-2 me-1">{"Enter Key"}</div>
                  <div className="flex items-center w-[150px] h-[40px] bg-[rgb(200,200,200)] p-2 ">{"Enter Value"}</div>
                </div>
              </div>

            </div>
        </div>

        <div className={`flex w-full bg-white h-[75px] justify-end items-center p-3`}>
          <div 
            className={`flex text-gray-500 font-bold cursor-pointer`}
            onClick={()=>setModalIsOpen(false)}
            >CLOSE</div>
        </div>
      </div>
     
      </FloatingPanel>

    </div>
  }

  </div>
    
 
  );
};

export default Generate;

