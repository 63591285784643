import React from "react";
import Lottie from "lottie-react";
import documentAnimation from "./document-animation.json"; // Place your Lottie JSON file here
import {useSelector} from 'react-redux'

const GeneratingModal = ({ isOpen, onClose }) => {

  const mode = useSelector(state=>state.environment.mode)
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      
      <div className={`panel-mode-${mode} p-6 rounded-2xl shadow-lg w-96 text-center`}>
        {/* Animated Document Icon */}
        <Lottie animationData={documentAnimation} loop autoplay className="w-32 h-32 mx-auto" />

        {/* Message */}
        <h4>Generating Document...</h4>
        <p className={`secondary-color-mode-${mode} mb-3 mt-3`}>
          This may take several minutes. <br /> You will receive an email when it's complete.
        </p>

        {/* Close Button */}
        <button
          onClick={onClose}
          className={`button-mode-${mode} justify-self-center`}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default GeneratingModal;
